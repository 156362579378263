import React from "react";
import CustomInput from "../../UI/PageInput/CustomInput";
import SearchDropdown from "../../UI/PageInput/SearchDropdown";
import DateInput from "../../UI/PageInput/DateInput";

const PersonalInfo = ({
  userSetup,
  register,
  errors,
  setValue,
  dob,
  selectedOption,
  setSelectedOption,
  isFormClear,
}) => {
  return (
    <div className="userInfoWrap mt-0">
      <h4 className="userInfoHeading">Personal Info</h4>
      <div className="flex flex-wrap">
        <div className="halfCol">
          <div className="px-1">
            <CustomInput
              type="text"
              name="fullName"
              labelText="Full Name"
              placeholder="Enter"
              register={{
                ...register("fullName"),
              }}
              error={errors.fullName}
            />
            <CustomInput
              type="number"
              name="salary"
              labelText="Salary"
              placeholder="Enter"
              register={{
                ...register("salary"),
              }}
              error={errors.salary}
            />
            <SearchDropdown
              name="genderId"
              label="Gender"
              keyName="name"
              id="id"
              options={userSetup.genders}
              setSelectedOption={setSelectedOption}
              selectedOption={selectedOption}
              setValue={setValue}
              register={{
                ...register("genderId"),
              }}
              error={errors.genderId}
            />
          </div>
        </div>
        <div className="halfCol">
          <div className="px-1">
            <DateInput
              label="Date of birth"
              name={"dob"}
              value={dob}
              setValue={setValue}
              register={{
                ...register("dob"),
              }}
              error={errors.dob}
              isFormClear={isFormClear}
              disableCurrentAndFutureDays={true}
            />
            <CustomInput
              type="text"
              name="mobile"
              labelText="Phone No."
              placeholder="Enter"
              register={{
                ...register("mobile", {
                  pattern: {
                    value: /^[0-9]{10}$/,
                    message: "Please enter a valid 10-digit phone number.",
                  },
                }),
              }}
              error={errors.mobile}
            />
            <CustomInput
              type="text"
              name="bikeRegNumber"
              labelText="Bike Registration Number"
              placeholder="Enter"
              register={{
                ...register("bikeRegNumber"),
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PersonalInfo;
