import { useSelector } from "react-redux";
import { setTableHeight } from "../../../functions/setTableHeight";
import { visitPendingHeadList } from "../../../utils/TableHeadList";
import { formatDate } from "../../../functions/dateTime/formatDate";
import NoDataDisplay from "../../UI/NoDataDisplay";
import sortDataByDate from "../../../functions/sorting/sortDataByDate";
import TableHead from "../../UI/TableUI/TableHead";
import { checkValue } from "../../../functions/checkValue";

const VisitTableContent = ({ openViewDialog }) => {
  const isSorted = useSelector((state) => state.data.isSorted);
  const data = useSelector((state) => state.data.data);
  let dataList = isSorted ? data : sortDataByDate(data, "visitedAt");
  const isDataAvailable = data.length > 0;

  return (
    <div className={`table-container ${setTableHeight(isDataAvailable)}`}>
      <table className={`table ${!isDataAvailable ? "relative" : ""}`}>
        <tbody>
          <TableHead headList={visitPendingHeadList} />
          {isDataAvailable ? (
            <>
              {dataList.map((data, index) => {
                return (
                  <tr className="table-row" key={index}>
                    <td className="p-3">{data.station.id}</td>
                    <td className="p-3">{data.station.name}</td>
                    <td className="p-3">
                      {checkValue(data.operatorPhoneNumber)}
                    </td>
                    <td className="p-3">{formatDate(data.visitedAt)}</td>
                    <td className="p-3">{data.fieldTechnician.fullName}</td>
                    <td className="p-3">
                      {data.category.name}{" "}
                      {data.category.name === "Complaint" &&
                        ` (${data.category.id})`}
                    </td>
                    <td className="p-3">{data.workDone}</td>
                    <td className="p-3">
                      <button
                        className="tableViewBtn"
                        onClick={() => openViewDialog(dataList[index])}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <NoDataDisplay />
          )}
        </tbody>
      </table>
    </div>
  );
};
export default VisitTableContent;