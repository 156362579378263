import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CenteredHeading from "../UI/headingsUI/CenteredHeading";
import CustomInput from "../UI/CustomInput";
import useShowHidePassword from "../../CustomHooks/useShowHidePassword";
import showPass from "../../assets/icons/showpass.svg";
import hidePass from "../../assets/icons/hidepass.svg";
import SuccessChangePassword from "./SuccessChangePassword";
import { logoutHandler } from "../../functions/logoutHandler";

const AddExpense = ({ sendData, isSucceed, loading }) => {
  const { showPassword, handleClickShowPassword } = useShowHidePassword();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();
  const newPassword = watch("newPass");
  const onSubmit = (data) => {
    const formData = {
      original: data.original.trim(),
      new: data.new.trim(),
    };
    sendData(formData, reset);
  };
  return (
    <>
      <div className="form-container">
        <div className="change--pass-border--wrap">
          <CenteredHeading title="Change Your Password" />
          <form
            className=""
            onSubmit={!loading ? (e) => handleSubmit(onSubmit)(e) : undefined}
          >
            <CustomInput
              type={showPassword.original ? "text" : "password"}
              name="original"
              labelText="Old Password"
              placeholder="Enter"
              register={{
                ...register("original", {
                  required: "Please Enter your old  password",
                }),
              }}
              handleClick={() => handleClickShowPassword("original")}
              inputIcon={showPassword.original ? hidePass : showPass}
              imgAlt="pass"
              error={errors.original}
            />
            <CustomInput
              type={showPassword.newPass ? "text" : "password"}
              name="newPass"
              labelText="New Password"
              placeholder="Enter"
              register={{
                ...register("newPass", {
                  required: "Please Enter a new password",
                  minLength: {
                    value: 6,
                    message: "Password must be at least 6 characters long",
                  },
                }),
              }}
              handleClick={() => handleClickShowPassword("newPass")}
              inputIcon={showPassword.newPass ? hidePass : showPass}
              imgAlt="pass"
              error={errors.newPass}
            />
            <CustomInput
              type={showPassword.new ? "text" : "password"}
              name="new"
              labelText="Confirm New Password"
              placeholder="Enter"
              register={{
                ...register("new", {
                  required: "Please Enter a confirm password",
                  validate: (value) =>
                    value === newPassword || "Passwords do not match",
                }),
              }}
              handleClick={() => handleClickShowPassword("new")}
              inputIcon={showPassword.new ? hidePass : showPass}
              imgAlt="pass"
              error={errors.new}
            />
            <p className="text-center mt-7">
              <button
                className={`change--pass-btn ${!loading ? "" : "disableBtn"}`}
              >
                {!loading ? "Change Password" : "Changing..."}
              </button>
            </p>
          </form>
        </div>
      </div>
      {isSucceed && (
        <SuccessChangePassword closeAction={() => logoutHandler(navigate)} />
      )}
    </>
  );
};
export default AddExpense;
