import React from "react";

const CenteredHeading = (props) => {
  return (
    <h2 className="text-2xl text-dark-blue font-poppins font-bold pb-7 pt-2 text-center sm:max-lg:text-22 2xs:max-sm:text-xl 2xs:max-md:bg-white">
      {props.title}
    </h2>
  );
};

export default CenteredHeading;
